<template>
  <YLayoutBodyMenu>
    <el-card v-loading="loading" style="min-height: 45rem">
      <el-row :gutter="40">
        <el-col :span="16">
          <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom" style="width: 100%">
            <div style="width: 45rem">
              <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">附件配置</div>
              <el-radio-group v-model="form.power_state">
                <el-radio v-for="(item,key) in powerStateOption" :key="key" :label="item.value">{{
                    item.label
                  }}
                </el-radio>
              </el-radio-group>
              <el-form>
                <el-form-item label="图片压缩接口APIKEY">
                  <el-input v-model="form.tinify_api_key"></el-input>
                </el-form-item>
                <el-form-item label="图片压缩最大宽度">
                  <el-input-number v-model="form.tinify_max_width"></el-input-number>px
                </el-form-item>
              </el-form>
              <div v-if="[1,2,3].includes(form.power_state)" class="y-desc" style="color: red">
                <div>接入远程附件后 上传的新图片、音频、视频文件 将不再储存到本地服务器</div>
                <div>一键上传功能需保存配置后再点击使用,上传后不删除本地服务器已存在图片、音频、视频文件</div>
              </div>
              <div style="margin-top: 2rem">
                <Qiniu @all-local-up="allLocalUp" @submit="edit" v-if="form.power_state === 1"></Qiniu>
                <AliOss @all-local-up="allLocalUp" @submit="edit" v-if="form.power_state === 2"></AliOss>
                <TxCos @all-local-up="allLocalUp" @submit="edit" v-if="form.power_state === 3"></TxCos>
              </div>
              <div v-if="form.power_state === 0" style="margin-top: 2rem;width: 100%">
                <el-button @click="edit" type="primary">保存配置</el-button>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">富文本Url替换</div>
            <div class="y-desc" style="color: red">本地url变更为远程附件url时旧url结尾应添加/attachment</div>
            <div class="y-desc" style="color: red">示例：http://abc.com/attachment</div>
            <div class="y-desc" style="color: red"><strong>如不理解此功能作用请联系客服，不要随意操作！！！</strong></div>
            <el-form>
              <el-form-item label="旧url">
                <el-input v-model="richTextForm.old"></el-input>
                <div class="y-desc" style="color: red">注：url开头加http://或https://结尾不加 ‘/’例：http://abc.com</div>
              </el-form-item>
              <el-form-item label="新url">
                <el-input v-model="richTextForm.now"></el-input>
                <div class="y-desc" style="color: red">注：url开头加http://或https://结尾不加 ‘/’例：http://abc.com</div>
              </el-form-item>
              <el-form-item>
                <el-button style="width: 100%" @click="richTextSubmit">执行</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>

    </el-card>
  </YLayoutBodyMenu>
</template>

<script>

import TxCos from "@/view/system/attach/TxCos";
import AliOss from "@/view/system/attach/AliOss";
import Qiniu from "@/view/system/attach/Qiniu";
import api from "@/api";
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "attach",
  components: {YLayoutBodyMenu, Qiniu, AliOss, TxCos},
  data() {
    return {
      form: {
        power_state: 0,
        tinify_max_width:0,
        tinify_api_key:"",
      },
      powerStateOption: [
        {label: "本地", value: 0},
        {label: "七牛云储存", value: 1},
        {label: "阿里OSS", value: 2},
        {label: "腾讯云储存", value: 3},
      ],
      loading: false,
      richTextForm: {
        old: "",
        now: "",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    richTextSubmit() {
      let con = this.$loading({
        text: "执行中请勿离开",
        body: "body"
      })
      this.$u.api.attach.richTextReplace(this.richTextForm).then(() => {
        con.close();
        this.$message.success("执行完成");
      }).catch(() => {
        con.close();
      })
    },
    allLocalUp() {
      this.$u.api.attach.remoteAllLocalUp().then(() => {
        this.$message.success("一键上传任务已启动，稍后系统将自动完成上传工作");
      })
    },
    load() {
      this.loading = true;
      this.$u.api.attach.remoteConf().then(res => {
        this.loading = false;
        this.form = res;
      })
    },
    edit() {
      this.$u.api.attach.remoteConfEdit(this.form).then(() => {
        this.$message.success("操作成功");
        api.common.globalSet();
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>